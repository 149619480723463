import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  adminKey,
  empKey,
  subadmin,
  cordinator,
} from "../../../config/RoleConfig";
import {
  CustRoute,
  EmpRoute,
  FilesRoute,
  FileExpenseRoute,
  OfficeExpenseRoute,
  dashboard,
  profile_details,
  DepositExpenseRoute,
  TodosRoute,
  LeaveRoute,
  ProjectRoute,
  SiteRoute,
  architectureRoute,
  liasoningRoute,
  InterioRoute,
} from "../../../config/RouteConfig";
import {
  getRole,
  getUserId,
  getToken,
  getUserRoles,
  isEmp,
  shouldAllowedOfRoles,
  superAccess,
  Cordinator,
} from "../../../services/useLocalStorage";
import { FileUrl } from "../../../config/file_url";
import { menu, image2svg } from "../../../UtilsComponent/UtilFunctions";
import { app_hostname } from "../../../config/CommonVariables";
import { callApi } from "../../../utils";
import {
  fetchEmployee,
  deleteEmployee,
  insertEmployee,
  fetchEmployeeById,
  updateEmployeeById,
} from "../../../config/config";
import EmployeeService from "../../../services/EmployeeService";

const Sidebar = () => {
  useEffect(() => {
    menu();
    image2svg();
  }, []);

  const validPath = () => {
    return (
      window.location.pathname == `/${FilesRoute.add}` ||
      window.location.pathname.split("/")[1] == `${FilesRoute.fileDetail}` ||
      window.location.pathname == `/${FilesRoute.edit}` ||
      window.location.pathname == `/${FilesRoute.listFileOnline}` ||
      window.location.pathname == `/${FilesRoute.listFileOffline}` ||
      window.location.pathname == `/${FilesRoute.listFileApproved}` ||
      window.location.pathname == `/${FilesRoute.listFileBuc}` ||
      window.location.pathname == `/${FilesRoute.listFileReject}` ||
      window.location.pathname == `/${FilesRoute.listFileProject}`
    );
  };

  const validPathForProject = () => {
    return (
      window.location.pathname == `/${ProjectRoute.projectDetails}` ||
      window.location.pathname == `/${ProjectRoute.listProjectOnline}` ||
      window.location.pathname == `/${ProjectRoute.listProjectOffline}` ||
      window.location.pathname == `/${ProjectRoute.listProjectApproved}` ||
      window.location.pathname == `/${ProjectRoute.listProjectBUC}` ||
      window.location.pathname == `/${ProjectRoute.listProjectReject}`
    );
  };

  return (
    <>
      <aside className="sidebar">
        <div className="logo">
          <a href="https://www.zaidexceldesign.com/" target={"_blank"}>
            <img src={`${FileUrl}/img/logo.png`} alt="ZED" />
          </a>
        </div>
        <ul className="sidebar__menu side-nav">
          <li
            className={
              window.location.pathname == "/dashboard"
                ? "side-nav-item active"
                : "side-nav-item"
            }
          >
            <Link to={`/${dashboard.list}`} className="side-nav-link">
              <img
                className="in__svg"
                src={`${FileUrl}/img/icons/category.svg`}
                alt="Right arrow"
              />{" "}
              <span>Dashboard</span>
            </Link>
          </li>
          {superAccess() ||
          // ?
          Cordinator(cordinator) ? (
            <>
              <li
                className={
                  window.location.pathname == `/${EmpRoute.list}` ||
                  window.location.pathname == `/${EmpRoute.add}` ||
                  window.location.pathname == `/${EmpRoute.team_profile_details}` ||
                  window.location.pathname.split("/")[1] == `${EmpRoute.edit}`
                    ? "side-nav-item active"
                    : "side-nav-item"
                }
              >
                <Link to={`/${EmpRoute.list}`} className="side-nav-link">
                  <img
                    className="in__svg"
                    src={`${FileUrl}/img/icons/two-user.svg`}
                    alt="Right arrow"
                  />
                  <span>Team Members</span>
                </Link>
              </li>
            </>
          ) : (
            ""
          )}

          {shouldAllowedOfRoles(getUserRoles(), [
            adminKey,
            empKey,
            subadmin,
            cordinator,
          ]) ? (
            ""
            // <li
            //   className={
            //     validPath()
            //       ? "side-nav-item has-child active"
            //       : "side-nav-item has-child"
            //   }
            //  >
            //   <a
            //     className={
            //       validPath() ? "side-nav-link" : "side-nav-link collapsed"
            //     }
            //     data-toggle="collapse"
            //     href="#sidebarFiles"
            //     role="button"
            //     aria-expanded={validPath() ? "true" : "false"}
            //     aria-controls="sidebarFiles"
            //   >
            //     <img
            //       className="in__svg"
            //       src={`${FileUrl}/img/icons/folder.svg`}
            //       alt="Right arrow"
            //     />
            //     <span>Files</span>
            //   </a>
            //   <div
            //     className={
            //       validPath()
            //         ? "side-nav-submenu collapse show"
            //         : "side-nav-submenu collapse"
            //     }
            //     id="sidebarFiles"
            //   >
            //     <ul className="list-unstyled">
            //       {/* className="nav-submenu-item" */}
            //       <li
            //         className={
            //           window.location.pathname.split("/")[2] ==
            //           `${FilesRoute?.listFileOnline.split("/")[1]}`
            //             ? "nav-submenu-item active"
            //             : "nav-submenu-item"
            //         }
            //       >
            //         <Link to={`/${FilesRoute.listFileOnline}`}>
            //           Online Process
            //         </Link>
            //       </li>
            //       <li
            //         className={
            //           window.location.pathname.split("/")[2] ==
            //           `${FilesRoute?.listFileOffline.split("/")[1]}`
            //             ? "nav-submenu-item active"
            //             : "nav-submenu-item"
            //         }
            //       >
            //         <Link to={`/${FilesRoute.listFileOffline}`}>
            //           Offline Process
            //         </Link>
            //       </li>
            //       <li
            //         className={
            //           window.location.pathname.split("/")[2] ==
            //           `${FilesRoute?.listFileApproved.split("/")[1]}`
            //             ? "nav-submenu-item active"
            //             : "nav-submenu-item"
            //         }
            //       >
            //         <Link to={`/${FilesRoute.listFileApproved}`}>Approved</Link>
            //       </li>
            //       <li
            //         className={
            //           window.location.pathname.split("/")[2] ==
            //           `${FilesRoute?.listFileBuc.split("/")[1]}`
            //             ? "nav-submenu-item active"
            //             : "nav-submenu-item"
            //         }
            //       >
            //         <Link to={`/${FilesRoute.listFileBuc}`}>B.U.C.</Link>
            //       </li>
            //       <li
            //         className={
            //           window.location.pathname.split("/")[2] ==
            //           `${FilesRoute?.listFileReject.split("/")[1]}`
            //             ? "nav-submenu-item active"
            //             : "nav-submenu-item"
            //         }
            //       >
            //         <Link to={`/${FilesRoute.listFileReject}`}>Reject</Link>
            //       </li>
            //       <li
            //         className={
            //           window.location.pathname.split("/")[2] ==
            //           `${FilesRoute?.listFileProject.split("/")[1]}`
            //             ? "nav-submenu-item active"
            //             : "nav-submenu-item"
            //         }
            //       > <Link to={`/${FilesRoute.listFileProject}`}>Project</Link>
                    
            //       </li>
            //     </ul>
            //   </div>
            // </li>
          ) : (
            ""
          )}


{superAccess([adminKey, subadmin]) ? (
            ""
            // <li
            //   className={
            //     window.location.pathname == `/${architectureRoute.list}` ||
            //     window.location.pathname == `/${architectureRoute.add}` ||
            //     window.location.pathname.split("/")[1] ==
            //       `${architectureRoute.preview}`
            //       ? "side-nav-item active"
            //       : "side-nav-item"
            //   }
            // >
            //   <Link className="side-nav-link" to={`/${architectureRoute.list}`}>
            //     <img
            //       className="in__svg"
            //       src={`${FileUrl}/img/icons/architecture.svg`}
            //     />
            //     <span>Architecture</span>
            //   </Link>
            // </li>
          ) : isEmp() ? (
            ""
            // <li
            //   className={
            //     window.location.pathname == `/${architectureRoute.list}` ||
            //     window.location.pathname == `/${architectureRoute.add}` ||
            //     window.location.pathname.split("/")[1] ==
            //       `${architectureRoute.preview}`
            //       ? "side-nav-item active"
            //       : "side-nav-item"
            //   }
            // >
            //   <Link className="side-nav-link" to={`/${architectureRoute.list}`}>
            //     <img
            //       className="in__svg"
            //       src={`${FileUrl}/img/icons/architecture.svg`}
            //     />
            //     <span>Architecture</span>
            //   </Link>
            // </li>
          ) : (
            ""
          )}


{/* {superAccess([adminKey, subadmin]) ? (
            <li
              className={
                window.location.pathname == `/${liasoningRoute.list}` ||
                window.location.pathname == `/${liasoningRoute.add}` ||
                window.location.pathname.split("/")[1] ==
                  `${liasoningRoute.preview}`
                  ? "side-nav-item active"
                  : "side-nav-item"
              }
            >
              <Link className="side-nav-link" to={`/${liasoningRoute.list}`}>
                <img
                  className="in__svg"
                  src={`${FileUrl}/img/icons/liasoning.svg`}
                />
                <span>Liasoning</span>
              </Link>
            </li>
          ) : isEmp() ? (
            <li
              className={
                window.location.pathname == `/${liasoningRoute.list}` ||
                window.location.pathname == `/${liasoningRoute.add}` ||
                window.location.pathname.split("/")[1] ==
                  `${liasoningRoute.preview}`
                  ? "side-nav-item active"
                  : "side-nav-item"
              }
            >
              <Link className="side-nav-link" to={`/${liasoningRoute.list}`}>
                <img
                  className="in__svg"
                  src={`${FileUrl}/img/icons/liasoning.svg`}
                />
                <span>Liasoning</span>
              </Link>
            </li>
          ) : (
            ""
          )} */}



{superAccess([adminKey, subadmin]) ? (
  ""
            // <li
            //   className={
            //     window.location.pathname == `/${InterioRoute.list}` ||
            //     window.location.pathname == `/${InterioRoute.add}` ||
            //     window.location.pathname.split("/")[1] ==
            //       `${InterioRoute.preview}`
            //       ? "side-nav-item active"
            //       : "side-nav-item"
            //   }
            // >
            //   <Link className="side-nav-link" to={`/${InterioRoute.list}`}>
            //     <img
            //       className="in__svg"
            //       src={`${FileUrl}/img/icons/interior.svg`}
            //     />
            //     <span>Interior</span>
            //   </Link>
            // </li>
          ) : isEmp() ? (
            ""
            // <li
            //   className={
            //     window.location.pathname == `/${InterioRoute.list}` ||
            //     window.location.pathname == `/${InterioRoute.add}` ||
            //     window.location.pathname.split("/")[1] ==
            //       `${InterioRoute.preview}`
            //       ? "side-nav-item active"
            //       : "side-nav-item"
            //   }
            // >
            //   <Link className="side-nav-link" to={`/${InterioRoute.list}`}>
            //     <img
            //       className="in__svg"
            //       src={`${FileUrl}/img/icons/interior.svg`}
            //     />
            //     <span>Interior</span>
            //   </Link>
            // </li>
          ) : (
            ""
          )}

        

          {superAccess() ? (
            <>
              <li
                className={
                  window.location.pathname == `/${CustRoute.list}` ||
                  window.location.pathname == `/${CustRoute.add}` ||
                  window.location.pathname.split("/")[1] == `${CustRoute.edit}`
                    ? "side-nav-item active"
                    : "side-nav-item"
                }
              >
                <Link className="side-nav-link" to={`/${CustRoute.list}`}>
                  <img
                    className="in__svg"
                    src={`${FileUrl}/img/icons/paper.svg`}
                    alt="Right arrow"
                  />
                  <span>Clients</span>
                </Link>
              </li>
            </>
          ) : (
            ""
          )}

          {superAccess() ? (
            <>
              <li
                className={
                  window.location.pathname == `/${DepositExpenseRoute.list}` ||
                  window.location.pathname == `/${DepositExpenseRoute.add}` ||
                  window.location.pathname.split("/")[1] ==
                    `${DepositExpenseRoute.edit}`
                    ? "side-nav-item active"
                    : "side-nav-item"
                }
              >
                <Link
                  className="side-nav-link"
                  to={`/${DepositExpenseRoute.list}`}
                >
                  <img
                    className="in__svg"
                    src={`${FileUrl}/img/icons/wallet.svg`}
                    alt="Right arrow"
                  />
                  <span>Deposit</span>
                </Link>
              </li>
            </>
          ) : (
            ""
          )}

          {
            // app_hostname.name !== window.location.hostname &&
              // ?
              !Cordinator(cordinator) && (
                <>
                  <li
                    className={
                      window.location.pathname == `/${FileExpenseRoute.list}` ||
                      window.location.pathname == `/${FileExpenseRoute.add}` ||
                      window.location.pathname.split("/")[1] ==
                        `${FileExpenseRoute.edit}`
                        ? "side-nav-item active"
                        : "side-nav-item"
                    }
                  >
                    <Link
                      to={`/${FileExpenseRoute.list}`}
                      className="side-nav-link"
                    >
                      <img
                        className="in__svg"
                        src={`${FileUrl}/img/icons/wallet.svg`}
                        alt="Right arrow"
                      />
                      <span>File Expenses</span>
                    </Link>
                  </li>
                  <li
                    className={
                      window.location.pathname ==
                        `/${OfficeExpenseRoute.list}` ||
                      window.location.pathname ==
                        `/${OfficeExpenseRoute.add}` ||
                      window.location.pathname.split("/")[1] ==
                        `${OfficeExpenseRoute.edit}`
                        ? "side-nav-item active"
                        : "side-nav-item"
                    }
                  >
                    <Link
                      to={`/${OfficeExpenseRoute.list}`}
                      className="side-nav-link"
                    >
                      <img
                        className="in__svg"
                        src={`${FileUrl}/img/icons/wallet.svg`}
                        alt="Right arrow"
                      />
                      <span>Office Expenses</span>
                    </Link>
                  </li>
                </>
              )
            //   :""
          }

          {superAccess([adminKey, subadmin]) ? (
            // || Cordinator?
            <li
              className={
                window.location.pathname == `/${TodosRoute.list}` ||
                window.location.pathname.split("/")[0] ==
                  `${TodosRoute.markTodo}` ||
                window.location.pathname.split()[0] ==
                  `${TodosRoute.reviewtodo}` ||
                window.location.pathname == `/${TodosRoute.add}` ||
                window.location.pathname.split("/")[0] == `${TodosRoute.edit}`
                  ? "side-nav-item active"
                  : "side-nav-item"
              }
            >
              <Link className="side-nav-link" to={`/${TodosRoute.list}`}>
                <img
                  className="in__svg"
                  src={`${FileUrl}/img/icons/activity.svg`}
                  alt="Right arrow"
                />
                <span>Todo</span>
              </Link>
            </li>
          ) : isEmp() ? (
            <li
              className={
                window.location.pathname == `/${TodosRoute.list}` ||
                window.location.pathname.split("/")[0] ==
                  `${TodosRoute.markTodo}` ||
                window.location.pathname.split()[0] ==
                  `${TodosRoute.reviewtodo}` ||
                window.location.pathname == `/${TodosRoute.add}` ||
                window.location.pathname.split("/")[0] == `${TodosRoute.edit}`
                  ? "side-nav-item active"
                  : "side-nav-item"
              }
            >
              <Link className="side-nav-link" to={`/${TodosRoute.list}`}>
                <img
                  className="in__svg"
                  src={`${FileUrl}/img/icons/activity.svg`}
                  alt="Right arrow"
                />
                <span> Your Tasks</span>
              </Link>
            </li>
          ) : (
            ""
          )}

          {isEmp() ? (
            <>
              <li
                className={
                  window.location.pathname == `/${LeaveRoute.list}` ||
                  window.location.pathname.split("/")[0] ==
                    `${LeaveRoute.previewLeave}` ||
                  window.location.pathname == `/${LeaveRoute.add}` ||
                  window.location.pathname.split("/")[0] == `${LeaveRoute.edit}`
                    ? "side-nav-item active"
                    : "side-nav-item"
                }
              >
                <Link className="side-nav-link" to={`/${LeaveRoute.list}`}>
                  <img
                    className="in__svg"
                    src={`${FileUrl}/img/icons/activity.svg`}
                    alt="Right arrow"
                  />
                  <span> {"Leaves"} </span>
                </Link>
              </li>
              {!Cordinator(cordinator) && (
                <li
                  className={
                    window.location.pathname ==
                      `/${EmpRoute.deposit_expense_report}` ||
                    window.location.pathname.split("/")[0] ==
                      `${EmpRoute.deposit_expense_report}` ||
                    window.location.pathname ==
                      `/${EmpRoute.deposit_expense_report}` ||
                    window.location.pathname.split("/")[0] ==
                      `${EmpRoute.deposit_expense_report}`
                      ? "side-nav-item active"
                      : "side-nav-item"
                  }
                >
                  <Link
                    className="side-nav-link"
                    to={`/${EmpRoute.deposit_expense_report}/${getUserId()}`}
                  >
                    <img
                      className="in__svg"
                      src={`${FileUrl}/img/icons/activity.svg`}
                      alt="Right arrow"
                    />
                    <span> Wallet </span>
                  </Link>
                </li>
              )}
            </>
          ) : (
            ""
          )}

          {superAccess() ? (
            <li
              className={
                window.location.pathname == `/${SiteRoute.list}` ||
                window.location.pathname == `/${SiteRoute.add}` ||
                window.location.pathname.split("/")[1] == `${SiteRoute.edit}`
                  ? "side-nav-item active"
                  : "side-nav-item"
              }
            >
              <Link className="side-nav-link" to={`/${SiteRoute.list}`}>
                <img
                  className="in__svg"
                  src={`${FileUrl}/img/icons/siteinspect.svg`}
                />
                <span>Site Inspection</span>
              </Link>
            </li>
          ) : (
            ""
          )}
          {isEmp() ? (
            <li
              className={
                window.location.pathname == `/${SiteRoute.emplist}` ||
                window.location.pathname == `/${SiteRoute.add}` ||
                window.location.pathname.split("/")[1] == `${SiteRoute.preview}`
                  ? "side-nav-item active"
                  : "side-nav-item"
              }
            >
              <Link className="side-nav-link" to={`/${SiteRoute.emplist}`}>
                <img
                  className="in__svg"
                  src={`${FileUrl}/img/icons/siteinspect.svg`}
                />
                <span>Site Inspection</span>
              </Link>
            </li>
          ) : (
            ""
          )}
        </ul>
      </aside>
    </>
  );
};

export default Sidebar;
