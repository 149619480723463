const fileRouteText = "file";
const projectRouteText = "project";

module.exports = {
    login:{
        list:"login"
    },
    dashboard:{
        list:"dashboard"
    },
    

    elements:{
        list:"elements"
    },
    profile_details:{
        list:"profile_details"
    },
    CustRoute:{
        list:"customers",
        add:"add-customer",
        edit:"edit-customer"
    },
    EmpRoute:{
        list:"teams",
        add:"add-team",
        edit:"edit-team",
        deposit_expense_report:"deposit-expense-report",
        deposit_expense_report_pdf:"deposit-expense-report-pdf",
        team_profile_details:"team-profile-details",
        EditProfile:"edit-profile"
    },
    DepositExpenseRoute:{
        list:"deposit-expense",
        add:"add-deposit-expense",
        edit:"edit-deposit-expense"
    },
    FileExpenseRoute:{
        list:"file-expenses",
        add:"add-file-expense",
        edit:"edit-file-expense",
        preview:"preview-file-expense"
    },
    OfficeExpenseRoute:{
        list:"office-expenses",
        add:"add-office-expense",
        edit:"edit-office-expense",
        preview:"preview-office-expense"
    },
    FilesRoute:{
        list:`${fileRouteText}`,
        listFileOnline:`${fileRouteText}/file-online`,
        listFileOffline:`${fileRouteText}/file-offline`,
        listFileBuc:`${fileRouteText}/file-buc`,
        listFileApproved:`${fileRouteText}/file-approved`,
        listFileReject:`${fileRouteText}/file-reject`,
        listFileProject:`${fileRouteText}/file-project`,
        add:`add-file`,
        edit:`edit-file`,
        fileDetail:`file-details`,
        listFileExpense:`${fileRouteText}/file-expense`,
        file_expense_pdf:`${fileRouteText}/file-expense-pdf`,
        file_todos : `${fileRouteText}/todos`
    },
    ProjectRoute:{
        listProjectOnline:`${projectRouteText}/project-online`,
        listProjectOffline:`${projectRouteText}/project-offline`,
        listProjectBUC:`${projectRouteText}/project-buc`,
        listProjectApproved:`${projectRouteText}/project-approved`,
        listProjectReject:`${projectRouteText}/project-reject`,
        projectDetails:`${projectRouteText}/project-details`
    },
    TodosRoute:{
        list:"todos",
        add:"add-todo",
        edit:"edit-todo",
        reviewtodo:"todo-review",
        markTodo:"mark-todo",
        hodreviewtodo:"hod-review-todo"
    },
    SearchRoute:{
        list:"search"
    },
    LeaveRoute:{
        list:"team-leave",
        add:"team-add-leave",
        edit:"team-edit-leave",
        previewLeave:"team-preview-leave",
        listLeaveOfTeam:"team-list-leave"
    },

    SiteRoute:{
        list:"sites",
        add:"add-sites",
        edit:"edit-sites",
        preview:"sites-preview",
        emplist:"emp-list"
    },
    InterioRoute:{
        list:"interior",
        add:"add-interior",
        edit:"edit-interior",
    },
    architectureRoute:{
        list:"architecture",
        add:"add-project",
        preview:"preview-project",
        edit:"edit-project",
        details:`${projectRouteText}/project-details`,
    },
    liasoningRoute:{
        list:"liasoning",
        add:"add-liasoning",
        edit:"edit-liasoning",
    }
}